import { format } from "date-fns";
import { getAggregationPrefix } from "../../Pages/TableEditor/SettingsMenu/Layout/Column/RegularColumn";
import { absoluteDate } from "../../utils/dates/dateFunc";
import {
  getWeekNameByWeekRange,
  getWeekNameByWeekRangeList,
  isDate,
} from "../../utils/formatters/dateFormatter";
import { allKeys, unique } from "../../utils/func";
import transformData from "./transformDataForRollupRows";
import { IO_UUID_FIELDS } from "./WriteBacksContext";
import formatter from "../../utils/formatters/formatter";
import { secialDateFormats } from "../../utils/constants/constants";
import tableFunctionConvertor from "./functions/tableFunctionConvertor";
import tableOpenMathFunctionConvertor from "./functions/tableOpenMathFunctionConvertor";

function getCheckedValues(filters, comparisonMode) {
  const { values } =
    filters
      .filter((f) => comparisonMode?.options?.includes(f.name))
      .find((f) => f.values.some((v) => v.checked)) ?? {};

  return values || [];
}

function getCheckedValueNames(values) {
  const names = values.filter((v) => v.checked);
  return [names[0]?.value, names[1]?.value];
}

function buildComparableHeaders(dynamicHighLevelKeys, values) {
  return dynamicHighLevelKeys.map((dk) =>
    dk.key
      ? { ...dk, key: dk.key === "[DYNAMIC_FILTER_1]" ? values[0] : values[1] }
      : dk
  );
}

export function setGroupingKeyMapping(key, weekOffset, groupingKeyMapping) {
  if (groupingKeyMapping === "[WEEK_RANGE]") {
    return getWeekNameByWeekRange(key, +weekOffset);
  }

  if (groupingKeyMapping === "[WEEK_RANGE_LIST]") {
    return getWeekNameByWeekRangeList(key, +weekOffset);
  }

  if (typeof key !== "string") {
    return key;
  }

  const clearedKey = (key ?? "").replace(/[A-z]/g, "");
  if (groupingKeyMapping === "[MONTH_RANGE]" && isDate(clearedKey)) {
    return format(absoluteDate(clearedKey), "MM/dd/yyyy");
  }

  if (groupingKeyMapping === "[MONTH_YEAR]" && isDate(clearedKey)) {
    return format(absoluteDate(clearedKey), "MMMM yyyy");
  }

  if (groupingKeyMapping === "[MONTHLY]") {
    return formatter(key, "monthly");
  }

  if (
    groupingKeyMapping &&
    isDate(key) &&
    !Number.isInteger(+key) &&
    !secialDateFormats.includes(groupingKeyMapping)
  ) {
    return format(absoluteDate(clearedKey), groupingKeyMapping);
  }

  return key;
}

function removeNulls(row, groupingKey) {
  if (!groupingKey) return true;
  return row[groupingKey] || row[groupingKey] === 0;
}

export default class {
  // columnKeys = null; // columnDefinition ne subTitles
  // headers = null; // display
  // sourceData = null;
  // data = null;
  // rows = null // to

  constructor(
    data,
    rollupOrSortedData,
    groupingKey,
    rowGroupKey,
    rowGroupKeys,
    filterNulls,
    weekOffsetKey
  ) {
    this.sourceData = data;
    this.data = rollupOrSortedData;
    this.groupingKey = groupingKey;
    this.rowGroupKey = rowGroupKey;
    this.postfixes = [];
    this.rowGroupKeys = rowGroupKeys ?? [];
    this.filterNulls = filterNulls;
    this.weekOffsetKey = weekOffsetKey;
  }

  setHighLevelHeaders(staticHighLevelKeys) {
    this.highLevelHeaders = staticHighLevelKeys ?? null;
  }

  setDynamicHighLevelHeaders(dynamicHighLevelKeys, filters, comparisonMode) {
    const values = getCheckedValues(filters, comparisonMode);
    const names = getCheckedValueNames(values);
    if (names[0] && names[1]) {
      this.highLevelHeaders = buildComparableHeaders(
        dynamicHighLevelKeys,
        names
      );
    }
  }

  setHeaders(
    valueKeyAsSubTitle,
    rowGroupKey,
    staticGroupingKeys,
    prefix = "",
    groupingKey,
    groupingKeyMapping
  ) {
    const mainHeader = valueKeyAsSubTitle
      ? []
      : [rowGroupKey, ...this.rowGroupKeys];
    const hasHeaders = mainHeader.concat(
      staticGroupingKeys ||
        unique(
          this.data
            .filter((row) => removeNulls(row, groupingKey))
            .map(matchKeysWithPrefix.bind(this))
        )
    );

    function matchKeysWithPrefix(d) {
      if (groupingKeyMapping) {
        const key = this.weekOffsetKey ?? "WeekOffset";
        return (
          prefix +
          setGroupingKeyMapping(d[groupingKey], d[key], groupingKeyMapping)
        );
      }

      return prefix + d[groupingKey];
    }

    function includeParentHeaders() {
      return (groupingKey || staticGroupingKeys) && hasHeaders;
    }

    this.headers = includeParentHeaders();
  }

  setSubTitles(
    subTitles,
    dynamicSubTitleKeys,
    groupingKeyMapping,
    dynamicPostfixColumn,
    overrides,
    rawRows,
    hiddenNullableColumns
  ) {
    const self = this;

    if (dynamicSubTitleKeys) {
      // Find the index of the first row where all values in the "values" array
      // have a truthy value for the specified "rowGroupKey".
      const nonNullValuesIndex = (this.rows ?? []).findIndex((row) =>
        (row.values ?? []).every((val) => val[this.rowGroupKey])
      );

      const index = nonNullValuesIndex === -1 ? 0 : nonNullValuesIndex;

      const values = (this.rows[index]?.values || []).filter((val) =>
        // related to filterNulls property in config for dynamicSubTitleKeys
        this.filterNulls ? removeNulls(val, this.groupingKey) : true
      );

      if (dynamicPostfixColumn) {
        this.postfixes = [
          "",
          ...values.map((row) => row[dynamicPostfixColumn]),
        ];
      }

      this.columnKeys = buildDynamicSubTitles(values);

      this.headers = buildDynamicNonUniqueHeaders(values, groupingKeyMapping);
    } else {
      const subTitleKeys = subTitles?.length
        ? subTitles
        : nestInArray(this.data);

      this.columnKeys = filterNullableSubTitles(
        this.sourceData,
        hiddenNullableColumns,
        subTitleKeys
      );
    }

    function nestInArray(data) {
      // for rawRows without subTitles we using overrides order
      // but we also have aggregation and we need add aggregation prefix to override name
      if (rawRows && overrides?.length) {
        return [
          overrides.map((override) => {
            const { aggregation, ops, name } = override;
            const currentAggregation = ops?.aggregation || aggregation;
            const prefix = getAggregationPrefix(currentAggregation) ?? "";
            return prefix + name;
          }),
        ];
      }

      return allKeys(data)
        .filter((column) => !IO_UUID_FIELDS.includes(column))
        .map((k) => [k]);
    }

    function buildDynamicSubTitles(values) {
      const quantity = values.length + self.rowGroupKeys.length;

      return [
        [self.rowGroupKey],
        ...[...Array(quantity).keys()].map(() => dynamicSubTitleKeys),
      ];
    }

    function buildDynamicNonUniqueHeaders(values, groupingKeyMapping) {
      const key = self.weekOffsetKey ?? "WeekOffset";

      return [(self.headers ?? ["--"])[0], ...self.rowGroupKeys].concat(
        values.map((val) =>
          setGroupingKeyMapping(
            val[self.groupingKey],
            val[key],
            groupingKeyMapping
          )
        )
      );
    }
  }

  setRollupData(rollup, data) {
    this.data = transformData(rollup, data);
  }

  setRollupSubTitles(rollup) {
    const { dynamicColumnKey, matchForRowLabelKey } = rollup;
    const rollupSubtitlesArray = uniqueColumnValues(
      this.sourceData,
      dynamicColumnKey
    );
    this.columnKeys = rollupSubtitlesArray.reduce(
      (acc, curr) => [...acc, [curr]],
      [[matchForRowLabelKey]]
    );
  }

  setRows(rows) {
    // related to filterNulls property in config for dynamicSubTitleKeys
    function removeNullsFromValues(item) {
      if (this.filterNulls && this.groupingKey) {
        return item[this.groupingKey] !== null;
      }

      return true;
    }

    this.rows = rows.map((row) => ({
      ...row,
      values: row.values.filter(removeNullsFromValues.bind(this)),
      uniqueRowUuid: row.values[0]?.uniqueRowUuid,
    }));
  }

  setProgressBarsTotals(totals) {
    this.progressBarTotals = totals;
  }
}

function uniqueColumnValues(data, key) {
  return unique(data.map((d) => d[key]));
}

export const removeExcessRows = (array = [], config, groupingKey) => {
  if (!config) {
    return array;
  }

  const { column, value, operator } = config;

  const res = array.reduce((acc, row) => {
    if (operator === "<" && row[column] < value) {
      acc.push({ [groupingKey]: row[groupingKey], WeekOffset: row[column] });
    } else {
      acc.push(row);
    }

    return acc;
  }, []);

  return res;
};

export function filterNullableSubTitles(data, items, subTitles) {
  if (!Array.isArray(items) || !Array.isArray(subTitles)) {
    return subTitles;
  }

  // Filter the items to find keys where all values in the corresponding rows are null, empty, or "--"
  const allNullKeys = items.filter((item) => {
    // Check if every row in the data array has a null, empty, or unformatted value for the current item
    return data.every((row) => {
      // Get the conversion function for the current item, if one exists
      const fn = getConvertorFunction(item);

      // If a conversion function exists, check if the formatted value is empty or not present
      if (fn) {
        const { formatted } = fn(item, row) ?? {};
        return !formatted || formatted === "--";
      }

      // If no conversion function, check if the value in the row is null or an empty string
      return row[item] === null || row[item] === "";
    });
  });

  // group is subTitle group => ["Receipts", "Receipts_LY", ...]
  return (subTitles || []).map((group) =>
    group ? group.filter((subTitle) => !allNullKeys.includes(subTitle)) : group
  );
}

// select formula convertor function (regular or open math formula)
function getConvertorFunction(item) {
  if (item.includes("fn::")) {
    return tableFunctionConvertor;
  } else if (item.includes("omf::")) {
    return tableOpenMathFunctionConvertor;
  }
}
